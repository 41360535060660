// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("D:\\La Voz\\lavoznewstc\\lavoznewstc-front-end\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("D:\\La Voz\\lavoznewstc\\lavoznewstc-front-end\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("D:\\La Voz\\lavoznewstc\\lavoznewstc-front-end\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("D:\\La Voz\\lavoznewstc\\lavoznewstc-front-end\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("D:\\La Voz\\lavoznewstc\\lavoznewstc-front-end\\src\\pages\\page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-past-editions-js": () => import("D:\\La Voz\\lavoznewstc\\lavoznewstc-front-end\\src\\pages\\past-editions.js" /* webpackChunkName: "component---src-pages-past-editions-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "D:\\La Voz\\lavoznewstc\\lavoznewstc-front-end\\.cache\\data.json")

